<script setup>
import { computed } from 'vue';
import config from '/tailwind.config.js';

const props = defineProps({
    status: {
        type: Boolean,
        required: true,
    }
})

const colors = computed(() => ({
    start: config.theme.extend.colors['success']['400'],
    end: config.theme.extend.colors['success']['500'],
    error: config.theme.extend.colors['danger']['400'],
}))

const outerColor = computed(() => props.status ? config.theme.extend.colors['success']['300'] : config.theme.extend.colors['danger']['300']);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        <defs v-if="status">
            <linearGradient id="gradientGreen" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" :stop-color="colors.start"/>
                <stop offset="100%" :stop-color="colors.end"/>
            </linearGradient>
        </defs>
        <circle cx="5" cy="5" r="5" :fill="outerColor"/>
        <circle cx="5" cy="5" r="2.5" :fill="status ? 'url(#gradientGreen)' : colors.error"/>
    </svg>
</template>
